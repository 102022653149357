<script setup lang="ts">
import { FeaturedNewsFieldsFragment } from '~/api/graphql/generated'
import { useNewsLatest } from '~/api/drupal'

const props = defineProps<{
  entity: FeaturedNewsFieldsFragment
}>()

const { items } = await useNewsLatest(6)

const data = computed(() => ({
  title: props.entity.fieldTitle || '',
  subTitle: props.entity.fieldDescription?.processed || '',
  teasers: items.value,
  link: {
    url: props.entity.fieldLink?.url?.path || '',
    target: props.entity.fieldLink?.options?.attributes.target || '_self',
    text: props.entity.fieldLink?.title || '',
  },
}))
</script>

<template>
  <TeaserSlider v-bind="data" />
</template>
